import React from "react";
import { Col } from "reactstrap";
import StarIcon from "../images/icon-star.svg";

const ReviewsCard = ({ rating, review, personName, personPosition, personImg, personImgAlt }) => (
	<Col sm="12" md="4" className="reviews-card">
		<div className="reviews-card-content p-4">
			<div className="d-flex flex-row align-items-center text-left reviews-card-content-rating">
				<img className="reviews-card-content-star" src={ StarIcon } alt="Star Rating"  />
				<p className="text-left m-0 ml-1">{ rating }</p>
			</div>
			<p className="mt-2 reviews-card-content-review">{ review }</p>
			<div className="d-flex flex-row mt-2 reviews-card-content-person">
				<div>
					<img src={ personImg } alt={ personImgAlt }  />
				</div>
				<div className="d-flex flex-column ml-2">
					<p className="m-0">{ personName }</p>
					<p className="m-0 color-subtext">{ personPosition }</p>
				</div>
			</div>
		</div>
	</Col>
);

export default ReviewsCard;
