import React from "react";

import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";
import { Button, Row } from "reactstrap";
import LockIcon from "../images/icon-lock.svg";
import FeaturesCard from "../components/FeaturesCard";
import ReviewsCard from "../components/ReviewsCard";
import RatingUser from "../images/placeholder-rating-user.png";

const Imprint = () => (
	<Layout>
		<SEO title="Features"/>
		<ContentContainer title="Features" subtitle="Lorem ipsum dolor sit amet, consectetur." half gutless className="features">
			<div className="text-center p-5">
				<h1 className="font-weight-bold mt-5">tinify.me is a Tool that kickasdf.jasödfjksaöd</h1>
				<p className="mt-3 color-subtext">Das Bild unten müsste mand ann in dem design von tinify.me startpage nachbauen</p>
				<img className="showcase-image" src="https://via.placeholder.com/800x400/000000/FFFFFF/?text=Tinify.me Startpage" alt="tinify showcase summary" />
			</div>
			<div className="section-features text-center px-5">
				<p className="font-weight-bold section-header">FEATURES</p>
				<h2 className="font-weight-bold mt-4">Lorem ipsum dolor</h2>
				<Row className="mt-6">
					<FeaturesCard title="Clean Code" text="With your logo design finalized, download a social media kit, business" img={LockIcon} />
					<FeaturesCard title="Clean Code" text="With your logo design finalized, download a social media kit, business" img={LockIcon} />
					<FeaturesCard title="Clean Code" text="With your logo design finalized, download a social media kit, business" img={LockIcon} />
					<FeaturesCard title="Clean Code" text="With your logo design finalized, download a social media kit, business" img={LockIcon} />
					<FeaturesCard title="Clean Code" text="With your logo design finalized, download a social media kit, business" img={LockIcon} />
					<FeaturesCard title="Clean Code" text="With your logo design finalized, download a social media kit, business" img={LockIcon} />
				</Row>
				<div className="features-cta-container">
					<div className="px-3 py-5">
						<h2 className="font-weight-bold">JOIN NOW LOREM IPSUM!</h2>
						<Link to="/pricing">
							<Button color="primary" className="features-cta-button px-5 py-3 mt-3">Get Started</Button>
						</Link>
					</div>
				</div>
			</div>
			<div className="section-reviews px-5">
				<p className="font-weight-bold section-header text-center">REVIEWS</p>
				<h2 className="font-weight-bold text-center mt-4">Why other people love to use our product</h2>
				<Row className="mt-5">
					<ReviewsCard rating={10}
								review="Jasmine really helped us to convert our visitors to our customers very quickly This has saved us an immense amount of time, allowing us to focus our efforts where it matters most — getting out there and doing what we love!"
								personName="Ashley George"
								personPosition="CEO at Company"
								personImg={RatingUser}
								personImgAlt="placeholder rating user"/>
					<ReviewsCard rating={10}
								review="Jasmine really helped us to convert our visitors to our customers very quickly This has saved us an immense amount of time, allowing us to focus our efforts where it matters most — getting out there and doing what we love!"
								personName="Ashley George"
								personPosition="CEO at Company"
								personImg={RatingUser}
								personImgAlt="placeholder rating user"/>
					<ReviewsCard rating={10}
								review="Jasmine really helped us to convert our visitors to our customers very quickly This has saved us an immense amount of time, allowing us to focus our efforts where it matters most — getting out there and doing what we love!"
								personName="Ashley George"
								personPosition="CEO at Company"
								personImg={RatingUser}
								personImgAlt="placeholder rating user"/>
				</Row>
			</div>
		</ContentContainer>
	</Layout>
);

export default Imprint;
