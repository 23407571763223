import React from "react";
import { Col } from "reactstrap";

const FeaturesCard = ({ title, text, img, imgAlt }) => (
	<Col sm="12" md="4" className="features-card">
		<div className="features-card-content text-center p-4">
			<div className="mt-3">
				<img src={ img } alt={ imgAlt }  />
			</div>
			<h3 className="mt-3">{ title }</h3>
			<p className="color-subtext mt-2">{ text }</p>
		</div>
	</Col>
);

export default FeaturesCard;
